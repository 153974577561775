<template>
  <div :before-change="validationForm">
    <validation-observer ref="accountRules" tag="form">
      <b-card :title="'Edite: ' + userData.username">
        <!-- Media -->
        <b-row class="">
          <!-- image profile picture -->
          <b-col md="4">
            <div align="center" class="text-center">
              <h4>Profile Picture</h4>
              <b-media class="mb-1 mr-auto">
                <b-avatar
                  class="rounded"
                  ref="previewEl"
                  :src="img"
                  size="180px"
                />
              </b-media>
              <div class="mb-4">
                <b-button variant="primary" @click="$refs.refInputEl.click()">
                  <input
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    @change="uploadImage"
                  />
                  <feather-icon icon="EditIcon" class=" " />
                </b-button>
              </div>
            </div>
          </b-col>
          <!-- image ID Front -->
          <b-col md="4">
            <div align="center" class="text-center">
              <h4>Id Front Picture</h4>
              <b-media class="mb-1">
                <b-avatar
                  class="rounded"
                  ref="previewEl"
                  :src="img2"
                  size="180px"
                />
              </b-media>
              <div class="mb-4">
                <b-button variant="primary" @click="$refs.refInputEl2.click()">
                  <input
                    ref="refInputEl2"
                    type="file"
                    class="d-none"
                    @change="uploadImage2"
                  />
                  <feather-icon icon="EditIcon" class="" />
                </b-button>
              </div>
            </div>
          </b-col>
          <!-- image ID Back -->
          <b-col md="4">
            <div align="center" class="text-center">
              <h4>Id Back Picture</h4>
              <b-media class="mb-2">
                <b-avatar
                  class="rounded"
                  ref="previewEl"
                  :src="img3"
                  size="180px"
                />
              </b-media>
              <div class="mb-4">
                <b-button variant="primary" @click="$refs.refInputEl3.click()">
                  <input
                    ref="refInputEl3"
                    type="file"
                    class="d-none"
                    @change="uploadImage3"
                  />
                  <feather-icon icon="EditIcon" class="" />
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- User Info: Input Fields -->
        <b-form>
          <b-row>
            <!-- Field: Username -->
            <b-col cols="12" md="4">
              <b-form-group label="Username" label-for="username">
                <b-form-input id="username" v-model="userData.username" />
              </b-form-group>
            </b-col>
            <!-- Field: First Name -->
            <b-col cols="12" md="4">
              <b-form-group label="Firstname" label-for="firstname">
                <b-form-input id="firstname" v-model="userData.firstname" />
              </b-form-group>
            </b-col>
            <!-- Field: Last Name -->
            <b-col cols="12" md="4">
              <b-form-group label="Lastname" label-for="lastname">
                <b-form-input id="lastname" v-model="userData.lastname" />
              </b-form-group>
            </b-col>
            <!-- Field: Email -->
            <b-col cols="12" md="4">
              <b-form-group label="Email" label-for="email">
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  type="email"
                />
              </b-form-group>
            </b-col>
            <!-- Field: address -->
            <b-col cols="12" md="4">
              <b-form-group label="Address" label-for="address">
                <b-form-input
                  id="address"
                  v-model="userData.address"
                  type="email"
                />
              </b-form-group>
            </b-col>
            <!-- Field: Phone -->
            <b-col cols="12" md="4">
              <b-form-group label="Phone" label-for="phone">
                <b-form-input id="phone" v-model="userData.phone" />
              </b-form-group>
            </b-col>
            <!-- Field: Gender -->
            <b-col cols="12" md="4">
              <b-form-group label="Gender" label-for="user-Gender">
                <v-select
                  id="gender"
                  v-model="genderSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="genderOptions"
                  :selectable="
                    (option) => !option.value.includes('select_value')
                  "
                  label="text"
                />
              </b-form-group>
            </b-col>
            <!-- Adminstration -->
            <b-col cols="12" md="4">
              <b-form-group label="Adminstration" label-for="adminstration">
                <v-select
                  id="adminstration"
                  v-model="adminstrationSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="adminstrationOptions"
                  :selectable="
                    (option) => !option.value.includes('select_value')
                  "
                  label="text"
                />
              </b-form-group>
            </b-col>
            <!-- Role -->
            <b-col cols="12" md="4">
              <b-form-group label="Role" label-for="role">
                <v-select
                  class="text-capitalize"
                  id="Role"
                  v-model="roleSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :selectable="
                    (option) => !option.value.includes('select_value')
                  "
                  label="text"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="validationForm"
          >
            Save Changes
          </b-button>
          <b-button variant="outline-secondary" type="reset" @click="backPage">
            Cancel
          </b-button>
        </b-form>
      </b-card>
    </validation-observer>
    <!-- handle error -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>

import { required, email } from '@validations'

export default {
  
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      img: '',
      img2: '',
      img3: '',
      userData: {},
      updateData: {
        password: '',
        password_confirmation: '',
      },
      showDismissibleAlert: false,
      genderSelect: {
        value: '',
        text: '',
      },
      genderOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'male',
          text: 'Male',
        },
        {
          value: 'female',
          text: 'Female',
        },
      ],
      adminstrationSelect: {
        value: '',
        text: '',
      },
      adminstrationOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'manager',
          text: 'Manager',
        },
        {
          value: 'admin',
          text: 'Admin',
        },
      ],
      roleSelect: {
        value: '',
        text: '',
      },
      roleOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      required,
      email,
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getProfile()
    // roles index
    axios
      .get('role/index')
      .then((result) => {
        const data = result.data.data
        for (let role in data) {
          this.roleOptions.push({
            value: data[role].name,
            text: data[role].name,
          })
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    // fetch data od admin
    getProfile() {
      axios
        .get('admins/' + this.id + '/show')
        .then((result) => {
          const data = result.data.data
          this.userData.username = data.username
          this.userData.firstname = data.firstname
          this.userData.lastname = data.lastname
          this.userData.email = data.email
          this.userData.address = data.address
          this.userData.phone = data.phone
          this.userData.role = data.role
          this.roleSelect.value = data.role
          this.roleSelect.text = data.role
          this.img = data.profile_picture
          this.img2 = data.idfront_picture
          this.img3 = data.idback_picture
          if (data.gender == 'male') {
            this.genderSelect.value = 'male'
            this.genderSelect.text = 'Male'
          } else {
            this.genderSelect.value = 'female'
            this.genderSelect.text = 'Female'
          }
          if (data.adminstration == 'manager') {
            this.adminstrationSelect.text = 'Manager'
            this.adminstrationSelect.value = 'manager'
          } else {
            this.adminstrationSelect.text = 'Admin'
            this.adminstrationSelect.value = 'admin'
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    backPage() {
      this.$router.back(1)
    },
    // submit updates
    validationForm() {
      this.$refs.accountRules.validate().then((success) => {
        if (success) {
          this.showDismissibleAlert = false
          if (this.img.includes('data:image/')) {
            this.userData.profile_picture = this.img
              .split(',')
              .map((item) => item.trim())
            this.userData.profile_picture = this.userData.profile_picture[1]
          }
          if (this.img2.includes('data:image/')) {
            this.userData.idfront_picture = this.img2
              .split(',')
              .map((item) => item.trim())
            this.userData.idfront_picture = this.userData.idfront_picture[1]
          }
          if (this.img3.includes('data:image/')) {
            this.userData.idback_picture = this.img3
              .split(',')
              .map((item) => item.trim())
            this.userData.idback_picture = this.userData.idback_picture[1]
          }
          this.userData.gender = this.genderSelect.value
          this.userData.adminstration = this.adminstrationSelect.value
          if (this.roleSelect.value != null || this.roleSelect.value != '') {
            this.userData.role = this.roleSelect.value
          }
          axios
            .post('admins/' + this.id + '/update', this.userData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // cencode profile picture
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        this.img = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // encode id front pictuer
    uploadImage2(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage2(files[0])
    },
    createImage2(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        this.img2 = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // encode id back picture
    uploadImage3(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage3(files[0])
    },
    createImage3(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        this.img3 = e.target.result
      }
      reader.readAsDataURL(file)
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">

</style>
